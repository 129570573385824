import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BetLimitPopupComponent } from './components/betlimitpopup.component';
import { ResizeModule } from 'src/framework/resize/resize.module';
import { DirectiveModule } from 'src/app/directives/directive.module';
import { PipeModule } from 'src/app/pipe/pipe.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    ResizeModule,
    PipeModule,
    DirectiveModule,
    TranslateModule,
  ],
  declarations: [BetLimitPopupComponent],
  exports: [BetLimitPopupComponent],
})
export class BetLimitPopupModule {}
