/**
 * Created by karthikeyan.k on 09-09-2019.
 */
export class FC21 {
  dealSpeed = 1;
  feltColor = 'green';
  gameName = 'fc21';
  gameSound = 'on';
  voiceSound = 'on';
  volume = 5;
}
