import { relaxPopupAlertComponent } from './template/relaxPopupAlert.layout.mobile.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DirectiveModule } from '../../directives/directive.module';
import { PipeModule } from '../../pipe/pipe.module';

@NgModule({
  declarations: [relaxPopupAlertComponent],
  imports: [CommonModule, TranslateModule, DirectiveModule, PipeModule],
  exports: [relaxPopupAlertComponent],
})
export class relaxPopupAlertTrigger {}
