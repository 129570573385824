import { Injectable } from '@angular/core';
import { PageConstant } from './constant/page.constant';
import { Settings } from './models/settings/settings';
import { GIInfoService } from './services/giinfo.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  constructor(public giInfoService: GIInfoService) {}

  public async initAppParams(): Promise<any> {
    return new Promise((resolve, reject) => {
      let URL = 'version.json';

      if (environment.envName === 'dev') {
        URL = 'assets/version.json';
      }

      this.giInfoService.getJSONFileData(URL).subscribe({
        next: (getJSONData) => {
          PageConstant.VERSION = getJSONData.assetsVersion;
          this.loadAppSettings().then(resolve).catch(reject);
        },
        error: (error) => reject(error),
      });
    });
  }

  private loadAppSettings = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      let URL = 'appsettings.json';

      if (environment.envName === 'dev') {
        URL = 'assets/appsettings.json';
      }

      this.giInfoService.getJSONFileData(URL).subscribe({
        next: (getJSONData) => {
          Settings.appSettings = getJSONData.application;
          resolve();
        },
        error: (error) => reject(error),
      });
    });
  };
}
