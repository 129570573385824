import { Inject, Injectable } from '@angular/core';
import { API } from '../constant/api';
import { $http } from '../util/HttpService';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { UtilService } from '../util/UtilService';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    @Inject($http) private http: $http,
    public utilService: UtilService,
    public userService: UserService
  ) {}

  mobileCheckLogin(data): Observable<string> {
    return this.http.post(API.MOBILECHECKLOGIN, data).pipe(
      map((response: MobileCheckLogin) => {
        if (response.status === LoginStatus.success) {
          this.userService.user.sessiontoken = response.sessiontoken;
        }
        return response.status;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  verifyEmail(data): Observable<any> {
    return this.http.post(API.EMAILVERIFICATION, data, false, 'text').pipe(
      map((response: any) => {
        return this.utilService.querystringToJSON(response);
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }
}

export interface MobileCheckLogin {
  balance: number;
  casinoId: string;
  currencyCode: string;
  sessionId: string;
  sessiontoken: string;
  status: LoginStatus;
  tables: any[];
  userId: string;
}

export enum LoginStatus {
  success = 'success',
}
