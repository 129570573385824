/**
 * Created by karthikeyan.k on 30-11-2018.
 */
export class LanguageConstant {
  public static get ENGLISH(): string {
    return 'en';
  }
  public static get SPANISH(): string {
    return 'es';
  }
  public static get CZECH(): string {
    return 'cs';
  }
  public static get DANISH(): string {
    return 'da';
  }
  public static get GERMAN(): string {
    return 'de';
  }
  public static get FINNISH(): string {
    return 'fi';
  }
  public static get FRENCH(): string {
    return 'fr';
  }
  public static get HEBREW(): string {
    return 'he';
  }
  public static get ARMENIAN(): string {
    return 'hy';
  }
  public static get INDONESIAN(): string {
    return 'id';
  }
  public static get ITALIAN(): string {
    return 'it';
  }
  public static get JAPANESE(): string {
    return 'ja';
  }
  public static get KOREAN(): string {
    return 'ko';
  }
  public static get NORWEGIAN(): string {
    return 'nb';
  }
  public static get DUTCH(): string {
    return 'nl';
  }
  public static get POLISH(): string {
    return 'pl';
  }
  public static get PORTUGUESE(): string {
    return 'pt-BR';
  }
  public static get RUSSIAN(): string {
    return 'ru';
  }
  public static get SWEDISH(): string {
    return 'sv';
  }
  public static get THAI(): string {
    return 'th';
  }
  public static get TURKISH(): string {
    return 'tr';
  }
  public static get VIETNAMESE(): string {
    return 'vi';
  }
  public static get CHINESE_TRADITIONAL(): string {
    return 'zh-Hant';
  }
  public static get CHINESE_SIMPLIFIED(): string {
    return 'zh-Hans';
  }
  public static get ARABIC(): string {
    return 'ar';
  }
  public static get BULGARIAN(): string {
    return 'bg';
  }
  public static get ESTONIAN(): string {
    return 'ee';
  }
  public static get GEORGIAN(): string {
    return 'ge';
  }
  public static get GREEK(): string {
    return 'gr';
  }
  public static get HINDI(): string {
    return 'hi-IN';
  }
  public static get HUNGARIAN(): string {
    return 'hu';
  }
  public static get LITHUANIAN(): string {
    return 'lt';
  }
  public static get CAMBODIAN(): string {
    return 'kh';
  }
  public static get LATVIAN(): string {
    return 'lv';
  }
  public static get MALTESE(): string {
    return 'mt';
  }
  public static get MALAYSIAN(): string {
    return 'my';
  }
  public static get FILIPINO(): string {
    return 'ph';
  }
  public static get ROMANIAN(): string {
    return 'ro';
  }
  public static get SLOVENIAN(): string {
    return 'si';
  }
  public static get SLOVAKIAN(): string {
    return 'sk';
  }
  public static get SERBIAN(): string {
    return 'sr-RS';
  }
  public static get TAMIL(): string {
    return 'ta-IN';
  }
  public static get UKRANIAN(): string {
    return 'ua';
  }

  public static get LANGUAGEDISPLAYCODE(): any {
    return {
      ar: 'LANG00517',
      hy: 'LANG00518',
      bg: 'LANG00519',
      kh: 'LANG00520',
      'zh-Hans': 'LANG00521',
      'zh-Hant': 'LANG00522',
      cs: 'LANG00523',
      da: 'LANG00524',
      nl: 'LANG00525',
      en: 'LANG00526',
      ee: 'LANG00527',
      ph: 'LANG00528',
      fi: 'LANG00529',
      fr: 'LANG00530',
      ge: 'LANG00531',
      de: 'LANG00532',
      gr: 'LANG00533',
      he: 'LANG00534',
      'hi-IN': 'LANG00535',
      hu: 'LANG00536',
      id: 'LANG00537',
      it: 'LANG00538',
      ja: 'LANG00539',
      ko: 'LANG00540',
      lv: 'LANG00541',
      lt: 'LANG00542',
      my: 'LANG00543',
      mt: 'LANG00544',
      nb: 'LANG00545',
      pl: 'LANG00546',
      'pt-BR': 'LANG00547',
      ro: 'LANG00548',
      ru: 'LANG00549',
      'sr-RS': 'LANG00550',
      sk: 'LANG00551',
      si: 'LANG00552',
      es: 'LANG00553',
      sv: 'LANG00554',
      'ta-IN': 'LANG00555',
      th: 'LANG00556',
      tr: 'LANG00557',
      ua: 'LANG00558',
      vi: 'LANG00559',
    };
  }

  public static get LANGUAGEFROMCODE(): any {
    return {
      en: '(54) en_English',
      es: '(55) es_Spanish',
      cs: '(50) cs_Czech',
      da: '(51) da_Danish',
      de: '(52) de_German',
      fi: '(56) fi_Finnish',
      fr: '(57) fr_French',
      he: '(60) he_Hebrew',
      hy: '(63) hy_Armenian',
      id: '(64) id_Indonesian',
      it: '(65) it_Italian',
      ja: '(66) ja_Japanese',
      ko: '(68) ko_Korean',
      nb: '(73) nb_Norwegian',
      nl: '(74) nl_Dutch',
      pl: '(76) pl_Polish',
      'pt-BR': '(77) pt-BR_Portuguese',
      ru: '(79) ru_Russian',
      sv: '(83) sv_Swedish',
      th: '(85) th_Thai',
      tr: '(86) tr_Turkish',
      vi: '(88) vi_Vietnamese',
      'zh-Hant': '(90) zh-Hant_Traditional Chinese',
      'zh-Hans': '(89) zh-Hans_Simplified Chinese',
      ar: '(48) ar_Arabic',
      bg: '(49) bg_Bulgarian',
      ee: '(53) ee_Estonian',
      ge: '(58) ge_Georgian',
      gr: '(59) gr_Greek',
      'hi-IN': '(61) hi-IN_Hindi',
      hu: '(62) hu_Hungarian',
      lt: '(69) lt_Lithuanian',
      kh: '(67) kh_Cambodian',
      lv: '(70) lv_Latvian',
      mt: '(71) mt_Maltese',
      my: '(72) my_Malaysian',
      ph: '(75) ph_Filipino',
      ro: '(78) ro_Romanian',
      si: '(80) si_Slovenian',
      sk: '(81) sk_Slovakian',
      'sr-RS': '(82) sr-RS_Serbian',
      'ta-IN': '(84) ta-IN_Tamil',
      ua: '(87) ua_Ukranian',
    };
  }
}
