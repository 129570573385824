import { Directive, ElementRef, Input } from '@angular/core';
import { ResizeController } from '../ResizeController';
import { Istage } from '../Istage';
import { UtilService } from '../../app/util/UtilService';

@Directive({ selector: '[resizeDom]' })
export class DomResizeDirective extends ResizeController {
  @Input('resizeDom') layoutdata: Istage;
  private elementList: Array<Node> = [];
  private boundsRectList: Array<any> = [];
  constructor(
    private el: ElementRef,
    public _utilService: UtilService
  ) {
    super(_utilService);
  }
  ngOnInit() {
    this.initialize();
    //setTimeout(()=>{this.initialize();},10);
  }
  initialize() {
    this.boundsRectList = undefined;
    this.boundsRectList = [];
    let nodeList: NodeList = this.el.nativeElement.getElementsByTagName('*');
    //console.log(" nodeList : "+nodeList+" len : "+nodeList.length);
    if (nodeList && nodeList.length != 0) {
      for (let i = 0; i < nodeList.length; i++) {
        let node: Node = nodeList[i];
        //console.log(" node : "+node.childNodes);
        // Make sure it's really an Element
        if (node.nodeType == Node.ELEMENT_NODE) {
          let htmlElement: HTMLElement = node as HTMLElement;
          this.elementList.push(node);
          let boundRect: any = {};
          //console.log(" htmlElement off set : "+ htmlElement.className+ ' left : '+ htmlElement.offsetLeft+' top : '+ htmlElement.offsetTop);
          if (htmlElement.localName != 'span') {
            boundRect.paddingLeft = parseInt(
              window.getComputedStyle(htmlElement).paddingLeft.replace('px', '')
            );
            boundRect.paddingTop = parseInt(
              window.getComputedStyle(htmlElement).paddingTop.replace('px', '')
            );
            boundRect.paddingRight = parseInt(
              window
                .getComputedStyle(htmlElement)
                .paddingRight.replace('px', '')
            );
            boundRect.paddingBottom = parseInt(
              window
                .getComputedStyle(htmlElement)
                .paddingBottom.replace('px', '')
            );

            boundRect.borderRadius = parseInt(
              window.getComputedStyle(htmlElement).borderRadius
            );
            boundRect.left = htmlElement.offsetLeft;
            boundRect.top = htmlElement.offsetTop;
            boundRect.width = htmlElement.offsetWidth;
            boundRect.height = htmlElement.offsetHeight;
            boundRect.borderWidth = parseInt(
              window.getComputedStyle(htmlElement).borderWidth
            );
            //console.log(" boundRect "+JSON.stringify(boundRect));
          }
          this.boundsRectList.push(boundRect);
          //console.log(" getBoundingClientRect ngOnInit className : " + htmlElement.className + " localName : " + htmlElement.localName + " json : " + JSON.stringify(boundRect));
        } else {
          console.error(' Not find the node type');
        }
      }
    }
    this.el.nativeElement.style.position = this.layoutdata.position;
    this.el.nativeElement.style.left = this._elementLeft + 'px';
    this.el.nativeElement.style.top = this._elementTop + 'px';
    this.el.nativeElement.style.width = this._elementWidth + 'px';
    this.el.nativeElement.style.height = this._elementHeight + 'px';
    this.el.nativeElement.style.transformOrigin = 'top left';
    this.el.nativeElement.style.overflow = this.overflow;
    this.assignStageVars(this.layoutdata);
    this.el.nativeElement.style.zIndex = this._zindex;
  }
  resize(
    newGameScale,
    offsetLeft,
    offsetTop,
    windowWidth,
    windowHeight,
    isBlockPortrait
  ) {
    //console.log("resize-DomResizeDirective newGameScale : ",newGameScale," offsetLeft : ", offsetLeft," offsetTop : ",offsetTop," windowWidth : ",windowWidth," windowHeight :",windowHeight);
    if (this.layoutdata == undefined) {
      return;
    }
    this.el.nativeElement.style.position = this.layoutdata.position;
    //this.el.nativeElement.style.transformOrigin = "top left";
    this.el.nativeElement.style.width =
      this._elementWidth * newGameScale + 'px';
    this.el.nativeElement.style.height =
      this._elementHeight * newGameScale + 'px';

    //console.log("nativeElement  offsetLeft "+ offsetLeft +' this._elementLeft '+this._elementLeft+" offsetTop "+ offsetTop +' this._elementTop '+this._elementTop);

    this.el.nativeElement.style.left =
      offsetLeft + this._elementLeft * newGameScale + 'px';
    this.el.nativeElement.style.top =
      offsetTop + this._elementTop * newGameScale + 'px';
    this.el.nativeElement.style.zIndex = this._zindex;
    if (this.stageVariables.isChildResize == undefined) {
      this.updateChild();
    } else {
      //console.log(" this.stageVariables isChildResize : "+JSON.stringify(this.stageVariables));
    }
    this.el.nativeElement.style.overflow = this.overflow;
    let scale: any = {};
    scale.scale = newGameScale;
    scale.scaleX = newGameScale;
    scale.scaleY = newGameScale;
    scale.offsetLeft = offsetLeft;
    scale.offsetTop = offsetTop;
    scale.isBlockPortrait = isBlockPortrait;
    if (this.compName == 'updatescale') {
      this._utilService.fontRatio = newGameScale;
      this._utilService.scale = scale;
    }
  }

  updateChild() {
    for (let size = 0; size < this.elementList.length; size++) {
      let htmlElement: HTMLElement = this.elementList[size] as HTMLElement;
      let boundRect = this.boundsRectList[size];
      //console.log("htmlElement className BEFORE : "+htmlElement.className +" localName : "+htmlElement.localName+" boundRect : "+JSON.stringify(boundRect));
      if (htmlElement.localName != 'span') {
        htmlElement.style.paddingLeft =
          boundRect.paddingLeft * this.gameScale + 'px';
        htmlElement.style.paddingTop =
          boundRect.paddingTop * this.gameScale + 'px';
        htmlElement.style.paddingRight =
          boundRect.paddingRight * this.gameScale + 'px';
        htmlElement.style.paddingBottom =
          boundRect.paddingBottom * this.gameScale + 'px';

        htmlElement.style.width = boundRect.width * this.gameScale + 'px';
        htmlElement.style.height = boundRect.height * this.gameScale + 'px';

        htmlElement.style.left = boundRect.left * this.gameScale + 'px';
        htmlElement.style.top = boundRect.top * this.gameScale + 'px';
        htmlElement.style.borderRadius =
          boundRect.borderRadius * this.gameScale + 'px';
        htmlElement.style.borderWidth =
          boundRect.borderWidth * this.gameScale + 'px';
        htmlElement.style.position = 'absolute';
      } else {
        //console.log('Device Info');
        //console.log(this._utilService.deviceInfo);
        /*if(this._utilService.deviceInfo.browser == 'chrome' && this._utilService.deviceInfo.os == 'mac'){
        //console.log('htmlElement.parentElement.style.fontFamily');
        //console.log(window.getComputedStyle(htmlElement.parentElement).fontFamily);
          if(window.getComputedStyle(htmlElement.parentElement).fontFamily != 'SerpentineBold' && this.compName != 'trendpanel') {
            htmlElement.parentElement.style.top = (htmlElement.parentElement.offsetTop + 1.5) * this.gameScale + 'px';
          }
        }*/
      }
      //console.log("htmlElement className AFTER : "+htmlElement.className +" width :"+htmlElement.offsetWidth+" height :"+htmlElement.offsetHeight);
    }
  }
  ngOnChanges() {
    //console.log("ngOnChanges : " +JSON.stringify(this.layoutdata));
    this.assignStageVars(this.layoutdata);
  }
  public setZIndex(index: number): void {
    this.stageVariables.stageZ = index;
  }
  public setCssTransform(transformProperty: string) {
    this.stageVariables.transform = transformProperty;
  }
  ngOnDestroy() {
    delete this.DEFAULT_GAME_WIDTH;
    delete this.DEFAULT_GAME_HEIGHT;
    delete this.gameWindowWidth;
    delete this.gameWindowHeight;
    delete this.gameLeft;
    delete this.gameTop;
    delete this.stageVariables;
    delete this._elementWidth;
    delete this._elementHeight;
    delete this._elementLeft;
    delete this._elementTop;
    delete this._zindex;
    //delete this.transformData;
    delete this.gameWindowWidth;
    delete this.gameWindowHeight;
    delete this.gameLeft;
    delete this.gameTop;
    delete this.layoutdata;
  }
}
