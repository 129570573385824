import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { ResizeModule } from '../../../../framework/resize/resize.module';
import { DirectiveModule } from '../../../directives/directive.module';
import { FCBTutorialsComponent } from './fcbtutorials.component';
import { FCBTutorialsMobileComponent } from './fcbtutorials.mobile.component';

@NgModule({
  declarations: [FCBTutorialsComponent, FCBTutorialsMobileComponent],
  imports: [
    CommonModule,
    TranslateModule,
    PinchZoomModule,
    ResizeModule,
    DirectiveModule,
  ],
  exports: [FCBTutorialsComponent, FCBTutorialsMobileComponent],
})
export class FCBTutorialsModule {}
