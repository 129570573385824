import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TutorialComponent } from './tutorial.component';
import { TutorialLayoutComponent } from './core/tutorial.layout.component';
import { TutorialLayoutDesktopComponent } from './desktop/tutorial.layout.desktop.component';
import { PipeModule } from '../../pipe/pipe.module';
import { TranslateModule } from '@ngx-translate/core';
import { FC21TutorialsModule } from '../gametutorials/fc21tutorials/fc21tutorials.module';
import { FCBTutorialsModule } from '../gametutorials/fcbtutorials/fcbtutorials.module';

@NgModule({
  declarations: [
    TutorialComponent,
    TutorialLayoutComponent,
    TutorialLayoutDesktopComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FC21TutorialsModule,
    FCBTutorialsModule,
  ],
  exports: [
    TutorialComponent,
    TutorialLayoutComponent,
    TutorialLayoutDesktopComponent,
  ],
})
export class TutorialModule {}
