import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FavoriteInfoComponent } from './favoriteinfo.component';
import { FavoriteInfoLayoutComponent } from './core/favoriteinfo.layout.component';
import { FavoriteInfoLayoutDesktopComponent } from './desktop/favoriteinfo.layout.desktop.component';
import { FormsModule } from '@angular/forms';
import { DirectiveModule } from '../../directives/directive.module';
import { TranslateModule } from '@ngx-translate/core';
import { PipeModule } from '../../pipe/pipe.module';
import { FavoriteInfoLayoutMobileComponent } from './mobile/favoriteinfo.layout.mobile.component';
import { MobileHeaderModule } from '../mobileheader/mobile.header.module';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [
    FavoriteInfoComponent,
    FavoriteInfoLayoutComponent,
    FavoriteInfoLayoutDesktopComponent,
    FavoriteInfoLayoutMobileComponent,
  ],
  imports: [
    CommonModule,
    DragDropModule,
    DirectiveModule,
    MobileHeaderModule,
    PipeModule,
    TranslateModule,
    FormsModule,
  ],
  exports: [
    FavoriteInfoComponent,
    FavoriteInfoLayoutComponent,
    FavoriteInfoLayoutDesktopComponent,
    FavoriteInfoLayoutMobileComponent,
  ],
})
export class FavoriteInfoModule {}
