import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FC21TutorialsComponent } from './fc21tutorials.component';
import { ResizeModule } from '../../../../framework/resize/resize.module';
import { TranslateModule } from '@ngx-translate/core';
import { DirectiveModule } from '../../../directives/directive.module';
import { FC21TutorialsMobileComponent } from './fc21tutorials.mobile.component';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';

@NgModule({
  declarations: [FC21TutorialsComponent, FC21TutorialsMobileComponent],
  imports: [
    CommonModule,
    TranslateModule,
    PinchZoomModule,
    ResizeModule,
    DirectiveModule,
  ],
  exports: [FC21TutorialsComponent, FC21TutorialsMobileComponent],
})
export class FC21TutorialsModule {}
