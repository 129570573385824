import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SpriteCacheService {
  common_icons = {};
  mobile_icons = {};
  lang = {};
  fc21_icons = {};
  fcb_icons = {};
  lobby = {};
  lobby_bg = {};
  tile_overlay_both = {};
  tile_bg_fc21 = {};
  tile_bg_fcb = {};
  cards = {};
  chips = {};
  buttonblank = {};
  langbutton = {};
  history_icons = {};
  history_cards = {};
  select_table_tile_overlay = {};

  rootContainer = {};

  constructor() {}
}
