import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  /* {path: '', loadChildren: () => import('./page/lobby/fcg/fcg.module').then(m => m.FCGModule)}, */
  /* {path: '', loadChildren: () => import('./page/index/index.module').then(m => m.IndexModule)}, */
  {
    path: 'index',
    loadChildren: () =>
      import('./page/index/index.module').then((m) => m.IndexModule),
  },
  {
    path: 'indexmobile',
    loadChildren: () =>
      import('./page/index/index.module').then((m) => m.IndexModule),
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./page/error/error.module').then((m) => m.ErrorModule),
  },
  {
    path: 'errormobile',
    loadChildren: () =>
      import('./page/error/error.module').then((m) => m.ErrorModule),
  },
  {
    path: 'landing',
    loadChildren: () =>
      import('./page/rfamalta/rfamalta.module').then((m) => m.RFAMaltaModule),
  },
  {
    path: 'historysummary',
    loadChildren: () =>
      import('./page/history/historysummary/historysummary.module').then(
        (m) => m.HistorySummaryModule
      ),
  },
  {
    path: 'historysummarycondensed',
    loadChildren: () =>
      import(
        './page/history/historysummarycondensed/historysummarycondensed.module'
      ).then((m) => m.HistorySummaryCondensedModule),
  },
  {
    path: 'historydetail',
    loadChildren: () =>
      import('./page/history/historydetail/historydetail.module').then(
        (m) => m.HistoryDetailModule
      ),
  },
  {
    path: 'fcg',
    loadChildren: () =>
      import('./page/lobby/fcg/fcg.module').then((m) => m.FCGModule),
  },
  {
    path: 'fcgmobile',
    loadChildren: () =>
      import('./page/lobby/fcgmobile/fcg.mobile.module').then(
        (m) => m.FCGMobileModule
      ),
  },
  {
    path: 'fc21',
    loadChildren: () =>
      import('./page/lobby/fc21/fc21.module').then((m) => m.FC21Module),
  },
  {
    path: 'fc21mobile',
    loadChildren: () =>
      import('./page/lobby/fc21mobile/fc21.mobile.module').then(
        (m) => m.FC21MobileModule
      ),
  },
  {
    path: 'fcb',
    loadChildren: () =>
      import('./page/lobby/fcb/fcb.module').then((m) => m.FCBModule),
  },
  {
    path: 'fcbmobile',
    loadChildren: () =>
      import('./page/lobby/fcbmobile/fcb.mobile.module').then(
        (m) => m.FCBMobileModule
      ),
  },
  {
    path: 'fc21tables',
    loadChildren: () =>
      import('./page/lobby/fc21tables/fc21tables.module').then(
        (m) => m.FC21TablesModule
      ),
  },
  {
    path: 'fcbtables',
    loadChildren: () =>
      import('./page/lobby/fcbtables/fcbtables.module').then(
        (m) => m.FCBTablesModule
      ),
  },
  {
    path: 'fc21tablesmobile',
    loadChildren: () =>
      import('./page/lobby/fc21tablesmobile/fc21tables.mobile.module').then(
        (m) => m.FC21TablesMobileModule
      ),
  },
  {
    path: 'fcbtablesmobile',
    loadChildren: () =>
      import('./page/lobby/fcbtablesmobile/fcbtables.mobile.module').then(
        (m) => m.FCBTablesMobileModule
      ),
  },
  /* {path: '**', loadChildren: () => import('./page/lobby/fcg/fcg.module').then(m => m.FCGModule)}, */
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
