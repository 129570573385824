/**
 * Created by karthikeyan.k on 03-10-2017.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomResizeDirective } from './resize.directive';
import { ResizeTextDirective } from './resizetext.directive';
@NgModule({
  imports: [CommonModule],
  declarations: [ResizeTextDirective, DomResizeDirective],
  providers: [],
  exports: [ResizeTextDirective, DomResizeDirective],
})
export class ResizeModule {}
