import { relaxPopupAlertTrigger } from './module/relaxPopupAlert/relaxPopupAlert.module';
import { PreloaderModule } from './module/preloader/preloader.module';
import { DecoderModule } from './module/decoder/decoder.module';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { createTranslateLoader } from './translate/translate.factory';
import { LoginService } from './services/login.service';
import { $http } from './util/HttpService';
import { UtilService } from './util/UtilService';
import { TutorialModule } from './module/tutorial/tutorial.module';
import { SelectInfoModule } from './module/selectinfo/selectinfo.module';
import { SelectHistoryModule } from './module/selecthistory/selecthistory.module';
import { SelectSettingsModule } from './module/selectsettings/selectsettings.module';
import { TableInfopopupModule } from './module/tableinfopopup/tableinfopopup.module';

import * as $ from 'jquery';
import * as createjs from 'createjs-module';
import 'hammerjs';
import { CookieService } from 'ngx-cookie-service';

import { ComingSoonModule } from './module/comingsoon/comingsoon.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SelectLanguageModule } from './module/selectlanguage/selectlanguage.module';
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { AppErrorModule } from './module/apperror/apperror.module';
import { ErrorPopupService } from './services/error.popup.service';

import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { FCGLayoutComponent } from './page/lobby/fcg/core/fcg.layout.component';
import { FC21LayoutComponent } from './page/lobby/fc21/core/fc21.layout.component';
import { FavoriteInfoModule } from './module/favoriteinfo/favoriteinfo.module';

import { PlayForFunAlertModule } from './module/playforfunalert/playforfunalert.module';
import { ResizeModule } from 'src/framework/resize/resize.module';
import { AlertPlayerRegisterPopupModule } from './module/alertPlayerRegisterPopup/alertPlayerRegisterPopup.module';
import { LoaderService } from './services/loader.service';
import { SelectSymbolsModule } from './module/selectsymbols/selectsymbols.module';
import { alertInsufficientPopupModule } from './module/alertInsufficientPopup/alertInsufficientPopup.module';
import { AlertLiveDealerNoSeatAvailableTrigger } from './module/alertlivedealernoseatavailable/alertlivedealernoseatavailable.module';
import { BetLimitPopupModule } from './module/betlimitpopup/betlimitpopup.module';

// import { initializeApp } from 'firebase/app';
// import { getAnalytics } from 'firebase/analytics';
import { environment } from '../environments/environment';
import { APP_BASE_HREF } from '@angular/common';

// import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';

import { AppInitService } from './app-init.service';

export function appInitializerFactory(appInitService: AppInitService) {
  return () => appInitService.initAppParams(); // Note: this function returns another function
}

// const app = initializeApp(environment.firebaseConfig);
// getAnalytics(app);

// const SentryTraceProviders = [
//   {
//     provide: ErrorHandler,
//     useValue: Sentry.createErrorHandler({
//       showDialog: false,
//     }),
//   },
//   {
//     provide: Sentry.TraceService,
//     deps: [Router],
//   },
//   {
//     provide: APP_INITIALIZER,
//     useFactory: () => () => {},
//     deps: [Sentry.TraceService],
//     multi: true,
//   },
// ];

@NgModule({
  declarations: [AppComponent, FCGLayoutComponent, FC21LayoutComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    TutorialModule,
    SelectInfoModule,
    SelectHistoryModule,
    SelectSettingsModule,
    SelectLanguageModule,
    TableInfopopupModule,
    PlayForFunAlertModule,
    AlertPlayerRegisterPopupModule,
    FavoriteInfoModule,
    HammerModule,
    alertInsufficientPopupModule,
    ComingSoonModule,
    AppErrorModule,
    DecoderModule,
    ResizeModule,
    PreloaderModule,
    ReactiveFormsModule,
    SelectSymbolsModule,
    AlertLiveDealerNoSeatAvailableTrigger,
    relaxPopupAlertTrigger,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
    BetLimitPopupModule,
    BrowserModule,
    BrowserAnimationsModule,
  ],
  providers: [
    ErrorPopupService,
    LoginService,
    $http,
    UtilService,
    LoaderService,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    { provide: APP_BASE_HREF, useValue: '/signin/viewer/mobile/lobby/' },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [AppInitService], // Add any dependencies here
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
