import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectInfoComponent } from './selectinfo.component';
import { SelectInfoLayoutComponent } from './core/selectinfo.layout.component';
import { SelectInfoLayoutDesktopComponent } from './desktop/selectinfo.layout.desktop.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DirectiveModule } from '../../directives/directive.module';

@NgModule({
  declarations: [
    SelectInfoComponent,
    SelectInfoLayoutComponent,
    SelectInfoLayoutDesktopComponent,
  ],
  imports: [CommonModule, TranslateModule, DirectiveModule, FormsModule],
  exports: [
    SelectInfoComponent,
    SelectInfoLayoutComponent,
    SelectInfoLayoutDesktopComponent,
  ],
})
export class SelectInfoModule {}
