import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreloaderLayoutComponent } from './core/preloader.layout.component';
import { TranslateModule } from '@ngx-translate/core';
import { PreloaderLayoutMobileComponent } from './mobile/preloader.layout.mobile.component';
import { PreloaderLayoutDesktopLandscape } from './desktop/preloader.layout.landscape.component';
import { PreloaderLayoutMobilePortrait } from './mobile/preloader.layout.portrait.component';
import { ResizeModule } from '../../../framework/resize/resize.module';
import { EllipsisLoaderComponent } from 'src/app/ellipsis.loader/ellipsis.loader.component';

@NgModule({
  declarations: [
    PreloaderLayoutComponent,
    PreloaderLayoutDesktopLandscape,
    PreloaderLayoutMobilePortrait,
    PreloaderLayoutMobileComponent,
    EllipsisLoaderComponent,
  ],
  imports: [CommonModule, TranslateModule, ResizeModule],
  exports: [
    PreloaderLayoutComponent,
    PreloaderLayoutDesktopLandscape,
    PreloaderLayoutMobilePortrait,
    PreloaderLayoutMobileComponent,
    EllipsisLoaderComponent,
  ],
})
export class PreloaderModule {}
