import { Inject, Injectable } from '@angular/core';
import * as global from '@rlx/feim';
import { IErrorMessage, on, send } from '@rlx/feim';
import { API } from '../constant/api';
import { $http } from '../util/HttpService';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/User';
@Injectable({
  providedIn: 'root',
})
export class RelaxGamingService {
  public configure;
  private user: User;
  constructor(@Inject($http) private http: $http) {}
  updateRelaxFreezeeReqRes(modeinfo): Observable<any> {
    // console.log('modeinfo',modeinfo);
    let modeobj: any = {};
    modeobj.mode = modeinfo;
    let api;
    api = API.USERRELAXFREEZELDSAPI;

    return this.http.get(api, modeobj, true).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  callconfigureInfo = (data, currncyCode, user: User) => {
    this.user = user;
    this.configure = {
      p2pConfig: { currency: currncyCode },
      handleRgPostMessageAPI: true,
      handleFeaturePause: true,
      showErrorMessageCallback: this.showInGameErrorMessage, // readonly showErrorMessageCallback?: (message: IErrorMessage) => Promise<void>;
    };
    console.log(
      'callconfigureInfo',
      data,
      currncyCode,
      'this.configure',
      this.configure
    );
    global.configure(this.configure);
    //console.log('this.userService.user',this.user);

    this.user.isRelaxConfigDone = true;
    //this.CheckfreezeStatus();

    this.addRelaxEventListener();
  };

  addRelaxEventListener = () => {
    send.updateSettings({ sounds: true });
    on.freeze(this.freeze);
    on.unfreeze(this.unfreeze);
  };

  /* CheckfreezeStatus = () =>{
  // console.log('CheckfreezeStatus');
  this.updateRelaxFreezeeReqRes(0).subscribe(eventResponse => {
  let freezeStatus =  JSON.parse(eventResponse);
  //console.log("updateRelaxFreezeeReqRes eventResponse-----",freezeStatus ,'eventResponse.description',freezeStatus.description)
  if(freezeStatus.description == "Freeze"){
    this.user.isFreezeAccount = true;
  } else if(freezeStatus.description == "UnFreeze"){
    this.user.isFreezeAccount = false;
  }
 });
} */
  freeze = () => {
    this.user.isFreezeAccount = true;
    // console.log('freeze');
    /*  {"status": {
      "code": 0,"message": "Success"
  },"description": "Freeze"
 } */
    /*   this.updateRelaxFreezeeReqRes(1).subscribe(eventResponse => {
 let freezeStatus =  JSON.parse(eventResponse);
//console.log("updateRelaxFreezeeReqRes eventResponse-----",freezeStatus ,'eventResponse.description',freezeStatus.description)
 if(freezeStatus.description == "Freeze"){
   this.user.isFreezeAccount = true;
 }
}); */
  };
  unfreeze = () => {
    this.user.isFreezeAccount = false;
    // console.log('unfreeze');
    /* {
"status": { "code": 0, "message": "Success" },
"description": "UnFreeze"
}
*/
    /* this.updateRelaxFreezeeReqRes(2).subscribe(eventResponse => {
  let freezeStatus =  JSON.parse(eventResponse);
 //console.log("updateRelaxFreezeeReqRes eventResponse-----",freezeStatus ,'eventResponse.description',freezeStatus.description)
  if(freezeStatus.description == "UnFreeze"){
    this.user.isFreezeAccount = false;
  }
}); */
  };

  onRealityCheckChoiceRequired = (choice) => {
    //console.log('onRealityCheckChoiceRequired----choice',choice);
  };
  async showInGameErrorMessage(message: IErrorMessage) {
    //console.log('showInGameErrorMessageawait message',message);
    try {
      const showErrorEvent = await global.showError(message);
    } catch (error) {
      console.log(error);
    }
  }
}
