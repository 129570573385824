import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectLanguageComponent } from './selectlanguage.component';
import { SelectLanguageLayoutComponent } from './core/selectlanguage.layout.component';
import { SelectLanguageLayoutDesktopComponent } from './desktop/selectlanguage.layout.desktop.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DirectiveModule } from '../../directives/directive.module';

@NgModule({
  declarations: [
    SelectLanguageComponent,
    SelectLanguageLayoutComponent,
    SelectLanguageLayoutDesktopComponent,
  ],
  imports: [CommonModule, TranslateModule, DirectiveModule, FormsModule],
  exports: [
    SelectLanguageComponent,
    SelectLanguageLayoutComponent,
    SelectLanguageLayoutDesktopComponent,
  ],
})
export class SelectLanguageModule {}
