import { Settings } from './../models/settings/settings';
import { environment } from './../../environments/environment';
/**
 * Created by karthikeyan.k on 22-11-2018.
 */
export class API {
  private static env = environment;
  public static get MOBILECHECKLOGIN(): string {
    return '/signin/viewer/mobile/mobileChecklogin.jsp';
  }

  public static get SESSIONOUTCLOSE(): string {
    return '/signin/viewer/mobile/sessionOutClose.jsp';
  }
  public static get USERRELAXFREEZELDSAPI(): string {
    return '/cgibin/PartnerFCGRNGGameServlet/relaxfreeze';
  }
  public static get EMAILVERIFICATION(): string {
    return '/signin/viewer/emailVerification.jsp';
  }

  public static get GI_INFO(): string {
    return '/viewer/signin/gameuserinfo.jsp';
  }
  public static get PLAYER_FAVORITE_HISTORY(): string {
    return '/member/playerFavoriteHistory.jsp';
  }

  public static get LIVEDEALERTABLES(): string {
    /* https://fcgrngdev.redimstg.com/cgibin/Lobby?gametype=0000000000000606&action=LiveDealerTables */
    return '/cgibin/Lobby';
  }
  public static get BET_LIMIT(): string {
    return '/cgibin/PartnerFCGRNGGameServlet/betlimit';
  }
  public static get GETFUNUSERGAME_DETAIL(): string {
    return '/cgibin/PartnerFCGRNGGameServlet/getfunusergamedetail';
  }

  public static get PLAYFOR_FUN(): string {
    return '/cgibin/PartnerFCGRNGGameServlet/playforfun';
  }

  public static get CHANGE_PASSWORD(): string {
    return '/signin/viewer/changePassword.jsp';
  }

  public static get USER_EMAIL_VALIDATION(): string {
    return '/signin/viewer/userEmailValidation.jsp';
  }

  public static get REGISTER_USER(): string {
    return '/signin/viewer/registerUser.jsp';
  }

  public static get LD_LAUNCH(): string {
    return '/cgibin/PartnerFCGRNGGameServlet/ldlaunchkey';
  }

  public static get RNGDETAILS(): string {
    return '/cgibin/LdsGameServlet/rngdetails';
  }

  public static get GAME_LIVEDEALER(): string {
    return '/signin/viewer/mobile/entry.jsp?';
  }

  public static get GAME_LIVEDEALERNEW(): string {
    return '/cgibin/LdsGameServlet/login?';
  }

  public static get GAME_RNG(): string {
    return '/cgibin/PartnerFCGRNGGameServlet/login?';
  }

  public static get GAME_STATE(): string {
    return '/cgibin/PartnerFCGRNGGameServlet/gamestate';
  }
  public static get GAME_STATE_LDS(): string {
    return '/cgibin/LdsGameServlet/gamestate';
  }
  public static get GAME_ENVIRONMENT(): string {
    return '/cgibin/PartnerFCGRNGGameServlet/environmentdescription';
  }

  public static get TIMEINFOLIVE(): string {
    return '/cgibin/LdsGameServlet/timeinfo';
  }

  public static get GAME_HISTORY_COUNT(): string {
    return Settings.appSettings.historyURI + 'HistorySummary/RecordCount';
  }

  public static get GAME_HISTORY(): string {
    // return '/cgibin/RNGGameHistoryServlet/history';
    return Settings.appSettings.historyURI + 'HistorySummary';
  }

  public static get GAME_DETAIL_HISTORY_LIST(): string {
    return Settings.appSettings.historyURI + 'DetailedGameHistory/UserId';
  }

  public static get GAME_HISTORY_DETAIL(): string {
    return Settings.appSettings.historyURI + 'DetailedGameHistory/GameId';
  }

  public static get REPORT_HISTORY(): string {
    return 'api/Game/GameHistory';
  }

  public static get FULL_COLOR_CARDS(): string {
    return 'http://fullcolorcards.com';
  }

  public static get FULL_COLOR_GAMES(): string {
    return 'http://fullcolorgames.com/';
  }

  public static get FULL_COLOR_GAMES_YOUTUBE(): string {
    return 'http://youtube.com/fullcolorgames';
  }

  public static get FULL_COLOR_GAMES_CHAT(): string {
    return 'http://fullcolorcasino.zendesk.com/hc';
  }

  public static get FULL_COLOR_GAMES_TWITCH(): string {
    return 'http://twitch.com/fullcolorcasino';
  }

  public static get FULL_COLOR_GAMES_MEDIUM(): string {
    return 'https://medium.com/@fullcolorcasino';
  }

  public static get FULL_COLOR_GAMES_FACEBOOK(): string {
    return 'https://facebook.com/fullcolorcasino';
  }

  public static get FULL_COLOR_GAMES_INSTAGRAM(): string {
    return 'https://www.instagram.com/fullcolorcasino';
  }

  public static get FULL_COLOR_GAMES_TWITTER(): string {
    return 'https://www.twitter.com/fullcolorcasino';
  }

  public static get FULL_COLOR_GAMES_COMMUNITY_FORUMS(): string {
    return 'https://fullcolorcasino.zendesk.com/hc/en-us/community/topics';
  }

  public static get FULL_COLOR_GAMES_FIX_OUR_TRANSLATIONS(): string {
    return 'https://fullcolorcasino.zendesk.com/hc/en-us/community/topics/360001192592-Fix-Our-Translations';
  }

  public static get FULL_COLOR_GAMES_HELP_DESK(): string {
    return 'https://fullcolorcasino.zendesk.com/hc';
  }

  public static get FULL_COLOR_GAMES_TUTORIAL(): string {
    return '/signin/viewer/mobile/lobby/help.html?';
  }

  /* https://fcgrngdev.redimstg.com/cgibin/PartnerRNGGameServlet/login?
   gametypeId=rngfcg21ornothing&betlimit=2&rewriteURL=/login/visitor/mobile/home/indexmain.html&newtable=0
   */
}
