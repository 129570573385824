/**
 * Created by karthikeyan.k on 23-11-2018.
 */
export class User {
  balance: number;
  casinoId: string;
  currencyCode: string;
  sessionId: string;
  status: string;
  tables: Array<any>;
  userId: string;
  sessiontoken: string;
  funUserBalance: any;
  fun_user_exist: any;
  usertypecode: any;
  fun_user_game_pending: any;
  fun_user_game_pending_checked: boolean = false;
  gameminbet: any;
  isAutoFullScreenDone: boolean = true;
  initialusertype: any = undefined;
  appusertype: any = undefined;
  isFlyLogin: boolean = false;
  reloadURL;
  isGameStarted: boolean = false;
  secretSquirrelKey = '';
  first_name: string;
  player_isblocked: boolean;
  rchistoryurl: any = undefined;
  rciframeurl: any = undefined;
  rcinterval: any = undefined;
  rcelapsed: any = undefined;
  rcenable: any = undefined;
  homeurl: any = undefined;
  fullscreen: any = undefined;
  isRelaxgame: boolean = false;
  isRelaxConfigDone: boolean = false;
  isFreezeAccount: boolean = false;
  isQueuedPlayer = false;
  isMenuLoaded = false;
  isSessionActive = true;
}
