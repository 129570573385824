import { Inject, Injectable } from '@angular/core';
import { $http } from '../util/HttpService';
import { API } from '../constant/api';
import { catchError, map } from 'rxjs/operators';
import { UtilService } from '../util/UtilService';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GIInfoService {
  private isLoginSuccessLoc = false;
  private isRFAMaltaLoc = false;

  constructor(
    @Inject($http) private http: $http,
    public utilService: UtilService
  ) {}

  set isLoginSuccess(bol) {
    this.isLoginSuccessLoc = bol;
  }

  get isLoginSuccess(): boolean {
    return this.isLoginSuccessLoc;
  }

  set isRFAMalta(bol) {
    this.isRFAMaltaLoc = bol;
  }

  get isRFAMalta(): boolean {
    return this.isRFAMaltaLoc;
  }
  getGameStateLds(table_id): Observable<any> {
    const dataObj: any = {
      table_id: table_id,
    };
    return this.http.post(API.GAME_STATE_LDS, dataObj, false, 'text').pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  callGIInfo(ispendinggame?): Observable<any> {
    //console.log("callGIInfo",ispendinggame);
    const sendData: any = {};
    if (ispendinggame) {
      sendData.ispendinggame = 'true';
    }

    sendData.lang = 'en';
    sendData.t = Date.now();
    return this.http.post(API.GI_INFO, sendData, false, 'text').pipe(
      map((response: any) => {
        return JSON.parse(response);
      }),
      catchError((error: any, caught: Observable<any>): any => {
        return { error: true };
      })
    );
  }

  getFunUserGameDetail(): Observable<any> {
    //console.log("getFunUserGameDetail");
    const sendData: any = {};
    sendData.t = Date.now();
    return this.http
      .post(API.GETFUNUSERGAME_DETAIL, sendData, false, 'text')
      .pipe(
        map((response: any) => {
          return JSON.parse(response);
        }),
        catchError((error: any, caught: Observable<any>): any => {
          return { error: true };
        })
      );
  }

  handleError(error: any, caught: Observable<any>): Observable<any> {
    return error as Observable<any>;
  }

  sessionOut(): Observable<any> {
    return this.http.post(API.SESSIONOUTCLOSE, {}, false, 'text').pipe(
      map((response: any) => {
        return this.utilService.querystringToJSON(response);
      })
    );
  }

  callPlayerFavorite(data): Observable<any> {
    return this.http
      .post(API.PLAYER_FAVORITE_HISTORY, data, false, 'text', false)
      .pipe(
        map((response: any) => {
          return this.utilService.querystringToJSON(response);
        })
      );
  }

  public getJSONFileData(URL): Observable<any> {
    return this.http.get(URL, {}).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

  public getEnvironmentData(): Observable<any> {
    return this.http.get(API.GAME_ENVIRONMENT, {}).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

  timeinfo(): Observable<any> {
    let uri = API.TIMEINFOLIVE;
    return this.http.get(uri, {}).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  public getRNGServerURL(): Observable<any> {
    return this.http.post(API.RNGDETAILS, {}, true, undefined, false).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }
  public getLaunchStatus(key: string): Observable<any> {
    const dataObj: any = {
      key: key,
    };
    return this.http.post(API.LD_LAUNCH, dataObj, true, undefined, false).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }
}
