import { Injectable } from '@angular/core';
import { User } from '../models/User';
import { RelaxGamingService } from './relaxGaming.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  user: User = new User();

  constructor(public relaxGamingService: RelaxGamingService) {}
  relaxConfiguration = (lobby, userCurrencyCode) => {
    if (!this.user.isRelaxConfigDone && this.user.isRelaxgame) {
      this.relaxGamingService.callconfigureInfo(
        lobby,
        userCurrencyCode,
        this.user
      );
    }
  };
}
