import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectSymbolsComponent } from './selectsymbols.component';
import { SelectSymbolsLayoutComponent } from './core/selectsymbols.layout.component';
import { FormsModule } from '@angular/forms';
import { DirectiveModule } from '../../directives/directive.module';
import { TranslateModule } from '@ngx-translate/core';
import { SelectSymbolsLayoutDesktopComponent } from './desktop/selectsymbols.layout.desktop.component';

@NgModule({
  declarations: [
    SelectSymbolsComponent,
    SelectSymbolsLayoutComponent,
    SelectSymbolsLayoutDesktopComponent,
  ],
  imports: [CommonModule, DirectiveModule, TranslateModule, FormsModule],
  exports: [
    SelectSymbolsComponent,
    SelectSymbolsLayoutComponent,
    SelectSymbolsLayoutDesktopComponent,
  ],
})
export class SelectSymbolsModule {}
