/**
 * Created by karthik.ar on 4/6/2017.
 */
import { ElementRef, HostListener, Directive } from '@angular/core';
import { Istage } from '../framework/Istage';
import { UtilService } from '../app/util/UtilService';
@Directive()
export class ResizeController {
  public DEFAULT_GAME_WIDTH: number = 0;
  public DEFAULT_GAME_HEIGHT: number = 0;
  public gameWindowWidth: number = 0;
  public gameWindowHeight: number = 0;
  public gameLeft: number = 0;
  public gameTop: number = 0;
  public stageVariables: Istage = {
    stageWidth: 0,
    stageHeight: 0,
    stageLeft: 0,
    stageTop: 0,
    stageZ: 0,
    position: 'absoulte',
    overflow: 'hidden',
  };
  public _elementWidth: number;
  public _elementHeight: number;
  public _elementLeft: number;
  public _elementTop: number;
  public _position: string;
  public _fontSize: number;
  public _zindex: number;
  public transformData: String;
  public element: ElementRef;
  public gameScale: number;
  public overflow: string;
  public compName: string;
  layoutStatic: boolean;

  public gamePosition: string = 'absolute';
  @HostListener('window:resize') onResize() {
    // console.log('OnResize');
    let resizeTimeOut = setTimeout(() => {
      this.getCurrentSize();
      this.resizeHandler();
      window.clearTimeout(resizeTimeOut);
    }, 300);
  }

  @HostListener('window:message', ['$event'])
  onMessage(event) {
    //console.log('window:message: parentHeight', event);
    // console.log('window.parent',window.parent)
    //console.log('window.parent.parent',window.parent.parent)
    //location.reload();
    if (event.data[0] && event.data[0].toLowerCase() == 'setchildheight') {
      this._utilService.parentHeight = event.data[1];
    }
    // console.log('iframeContentHeight :' + this._utilService.parentHeight);
    this.onResize();
  }

  private getCurrentSize() {
    let gameSize = this._utilService.getGameSize();
    this.DEFAULT_GAME_WIDTH = gameSize.width;
    this.DEFAULT_GAME_HEIGHT = gameSize.height;
  }

  protected afterDomLoaded(): void {}

  constructor(public _utilService: UtilService) {
    this.getCurrentSize();
  }

  assignStageVars(stageVar: Istage) {
    //console.log("assignStageVars gameWidth" + window.innerWidth + " gameHeight" + window.innerHeight);
    this.stageVariables = stageVar;

    //console.log("this.stageVariables ", this.stageVariables);
    if (this.stageVariables.transform) {
      this.transformData = this.stageVariables.transform;
    }
    this.gameWindowWidth = this.DEFAULT_GAME_WIDTH;
    this.gameWindowHeight = this.DEFAULT_GAME_HEIGHT;
    this.gameLeft = 0;
    this.gameTop = 0;
    this._elementWidth = this.stageVariables.stageWidth;
    this._elementHeight = this.stageVariables.stageHeight;
    this._elementLeft = this.stageVariables.stageLeft;
    this._fontSize = this.stageVariables.fontSize;
    this._elementTop = this.stageVariables.stageTop;
    this._position = this.stageVariables.position;
    this._zindex = this.stageVariables.stageZ;
    this.overflow = this.stageVariables.overflow;
    this.compName = this.stageVariables.name;
    this.resizeHandler();
  }

  public resizeHandler() {
    //console.log("resizeHandler gameWidth" + window.innerWidth + " gameHeight" + window.innerHeight);

    let windowWidth: number = window.innerWidth;
    let windowHeight: number =
      this._utilService.parentHeight != 0
        ? this._utilService.parentHeight
        : window.innerHeight;

    let newWidth: number;
    let newHeight: number;
    //var newGameScaleX: number;
    //var newGameScaleY: number;
    let leftOffset: number = 0;
    let topOffset: number = 0;
    let isBlockPortrait: boolean = false;

    let wider: boolean =
      windowWidth / windowHeight >
      this.DEFAULT_GAME_WIDTH / this.DEFAULT_GAME_HEIGHT
        ? true
        : false;

    // console.log('window wider');
    // console.log(windowHeight);
    // If the current window dimensions are WIDER than the game dimensions then WINDOW HEIGHT is our limiting size
    if (wider) {
      newHeight = windowHeight; // Set the height to the window height
      newWidth =
        windowHeight * (this.DEFAULT_GAME_WIDTH / this.DEFAULT_GAME_HEIGHT); // Set the width proportional to the height
      this.gameScale = windowHeight / this.DEFAULT_GAME_HEIGHT; // Calculate the new scaler
      leftOffset = (windowWidth - newWidth) / 2; // Find the new horizontal center of the page
    }
    // Otherwise, the window dimensions are HIGHER/TALLER than the game dimensions so the WINDOW WIDTH is the limiting factor
    else {
      newWidth = windowWidth; // Set the width to the window width
      newHeight =
        windowWidth * (this.DEFAULT_GAME_HEIGHT / this.DEFAULT_GAME_WIDTH); // Set the height proportional to the width
      this.gameScale = windowWidth / this.DEFAULT_GAME_WIDTH; // Calculate the new scaler
      topOffset = (windowHeight - newHeight) / 2; // Find the new vertical center of the page
    }
    //newGameScaleX = newGameScaleY = newGameScale;
    windowHeight > windowWidth
      ? (isBlockPortrait = true)
      : (isBlockPortrait = false);
    this.resize(
      this.gameScale,
      leftOffset,
      topOffset,
      windowWidth,
      windowHeight,
      isBlockPortrait
    );
  }

  protected resize(
    newGameScale: number,
    offsetLeft: number,
    offsetTop: number,
    windowWidth: number,
    windowHeight: number,
    isBlockPortrait: boolean
  ): void {}
}
