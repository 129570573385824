import { PipeModule } from './../../pipe/pipe.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DirectiveModule } from '../../directives/directive.module';
import { ResizeModule } from 'src/framework/resize/resize.module';
import { DecoderComponent } from './decoder.component';
import { DecoderLayoutComponent } from './core/decoder.layout.component';
import { DecoderLayoutDesktopComponent } from './desktop/decoder.layout.desktop.component';
import { DecoderLayoutMobileComponent } from './mobile/decoder.layout.mobile.component';

@NgModule({
  declarations: [
    DecoderComponent,
    DecoderLayoutComponent,
    DecoderLayoutDesktopComponent,
    DecoderLayoutMobileComponent,
  ],
  imports: [
    CommonModule,
    PipeModule,
    TranslateModule,
    ResizeModule,
    DirectiveModule,
    FormsModule,
  ],
  exports: [
    DecoderComponent,
    DecoderLayoutComponent,
    DecoderLayoutDesktopComponent,
    DecoderLayoutMobileComponent,
  ],
})
export class DecoderModule {}
