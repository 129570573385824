import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DirectiveModule } from '../../directives/directive.module';
import { PipeModule } from '../../pipe/pipe.module';
import { MobileHeaderModule } from '../mobileheader/mobile.header.module';
import { AlertPlayerRegisterPopupLayoutComponent } from './core/alertPlayerRegisterPopup.layout.component';
import { AlertPlayerRegisterPopupMobileComponent } from './mobile/alertPlayerRegisterPopup.layout.mobile.component';

@NgModule({
  declarations: [
    AlertPlayerRegisterPopupLayoutComponent,
    AlertPlayerRegisterPopupMobileComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    DirectiveModule,
    MobileHeaderModule,
    PipeModule,
    FormsModule,
  ],
  exports: [
    AlertPlayerRegisterPopupLayoutComponent,
    AlertPlayerRegisterPopupMobileComponent,
  ],
})
export class AlertPlayerRegisterPopupModule {}
