import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MobileHeaderLayoutMobileComponent } from './mobile/mobile.header.layout.mobile.component';
import { MobileHeaderLayoutComponent } from './core/mobile.header.layout.component';
import { MobileHeaderComponent } from './mobile.header.component';
import { DirectiveModule } from '../../directives/directive.module';
import { MobileHeaderLayoutDesktopComponent } from './desktop/mobile.header.layout.desktop.component';

@NgModule({
  declarations: [
    MobileHeaderComponent,
    MobileHeaderLayoutComponent,
    MobileHeaderLayoutMobileComponent,
    MobileHeaderLayoutDesktopComponent,
  ],
  imports: [CommonModule, TranslateModule, FormsModule, DirectiveModule],
  exports: [
    MobileHeaderComponent,
    MobileHeaderLayoutComponent,
    MobileHeaderLayoutMobileComponent,
    MobileHeaderLayoutDesktopComponent,
  ],
})
export class MobileHeaderModule {}
