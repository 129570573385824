import { SpriteCacheService } from './spritecache.service';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class LoaderService {
  currentManifest;
  loadCompleteChange: Subject<any> = new Subject<any>();
  progress: Subject<number> = new Subject<number>();

  private queue = new createjs.LoadQueue();
  constructor(public spriteCache: SpriteCacheService) {
    this.queue.setMaxConnections(10);
    this.queue.on('complete', this.handleComplete, this);
    this.queue.on('progress', this.handleProgress, this);
    this.queue.on('error', this.handleError, this);
    this.queue.on('fileload', this.handleFileLoad);
  }

  loadImages(manifest, currentManifest = undefined) {
    // console.log('Load Service   ::::::' + currentManifest);
    // console.log(manifest);
    this.currentManifest = currentManifest;
    // let queue = new createjs.LoadQueue();

    //queue.setPreferXHR(false);
    this.queue.loadManifest(manifest);
  }
  handleError = (e) => {
    // console.log(e);
  };
  handleFileLoad = (e) => {
    //console.log(e.item.id);
    if (e.item.id.includes('sprite')) {
      this.spriteCache.rootContainer[e.item.id] = e.result;
    }
  };
  handleComplete = () => {
    // console.log('LOAD SERVICE ON HANDLE COMPLETE');
    this.loadCompleteChange.next(this.currentManifest);
  };

  handleProgress = (e) => {
    this.progress.next(Math.round(e.loaded * 100));
  };
}
