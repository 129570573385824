import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComingSoonComponent } from './comingsoon.component';
import { ComingSoonLayoutComponent } from './core/comingsoon.layout.component';
import { ComingSoonLayoutDesktopComponent } from './desktop/comingsoon.layout.desktop.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DirectiveModule } from '../../directives/directive.module';
import { ComingSoonLayoutMobileComponent } from './mobile/comingsoon.layout.mobile.component';

@NgModule({
  declarations: [
    ComingSoonComponent,
    ComingSoonLayoutComponent,
    ComingSoonLayoutDesktopComponent,
    ComingSoonLayoutMobileComponent,
  ],
  imports: [CommonModule, TranslateModule, DirectiveModule, FormsModule],
  exports: [
    ComingSoonComponent,
    ComingSoonLayoutComponent,
    ComingSoonLayoutDesktopComponent,
    ComingSoonLayoutMobileComponent,
  ],
})
export class ComingSoonModule {}
