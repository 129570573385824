/**
 * Created by karthikeyan.k on 06-03-2019.
 */
export class DeviceConstant {
  public static get DEVICES(): any {
    return {
      '1': 'laptop',
      '2': 'desktop',
      '3': 'phone',
      '4': 'pad',
    };
  }
}

export enum OperatingSystemType {
  WINDOWS = 1,
  MAC = 2,
  IOS = 3,
  ANDROID = 4,
  LINUX = 5,
  OTHERS = 5,
}

export enum DeviceName {
  WINDOWS = 1,
  ANDROID = 2,
  IPAD = 3,
  IPHONE = 4,
  MACINOTOSH = 5,
  FIREFOX = 6,
}
