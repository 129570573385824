export const environment = {
  production: true,
  history: 'https://historyapi-int.fcg-live.com/', //'https://fcglivedealerplayer.redimstg.com/',
  envName: 'prod',
  firebaseConfig: {
    apiKey: 'AIzaSyBk5JViROouI-PXJloNxWdnFXfY9WIhdeo',
    authDomain: 'fcgstg-project.firebaseapp.com',
    projectId: 'fcgstg-project',
    storageBucket: 'fcgstg-project.appspot.com',
    messagingSenderId: '280033874571',
    appId: '1:280033874571:web:e49af5b6d6f662cb913a8e',
    measurementId: 'G-91ERVCSZZX',
  },
};
// ng build --base-href /signin/viewer/mobile/lobby/ --configuration=production

// ng build help --base-href /signin/viewer/mobile/lobby/ --configuration=production --output-path=dist_help

// Run Both the command separately and Deploy both the build in the same deployment folder
