import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectSettingsComponent } from './selectsettings.component';
import { SelectSettingsLayoutComponent } from './core/selectsettings.layout.component';
import { FormsModule } from '@angular/forms';
import { DirectiveModule } from '../../directives/directive.module';
import { TranslateModule } from '@ngx-translate/core';
import { SelectSettingsLayoutDesktopComponent } from './desktop/selectsettings.layout.desktop.component';

@NgModule({
  declarations: [
    SelectSettingsComponent,
    SelectSettingsLayoutComponent,
    SelectSettingsLayoutDesktopComponent,
  ],
  imports: [CommonModule, DirectiveModule, TranslateModule, FormsModule],
  exports: [
    SelectSettingsComponent,
    SelectSettingsLayoutComponent,
    SelectSettingsLayoutDesktopComponent,
  ],
})
export class SelectSettingsModule {}
