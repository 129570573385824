<!--The content below is only a placeholder and can be replaced.-->
<div
  *ngIf="userService.user.isFreezeAccount && userService.user.isRelaxgame"
  class="relaxFreezeLayer"
  style="
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: rgb(0 0 0 / 20%);
    z-index: 900;
  "
></div>

<app-preloaderlayoutlandscape
  *ngIf="utilService.showAppPreloader && !isPortrait"
  style="
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.99);
    z-index: 50;
  "
  [currentLanguage]="appLanguage"
  [layoutdata]="{
    stageWidth: 1280,
    stageHeight: 720,
    stageLeft: 0,
    stageTop: 0,
    stageZ: 1000,
    position: 'absolute',
    overflow: 'hidden',
    name: 'updatescale'
  }"
  [manifest]="[]"
></app-preloaderlayoutlandscape>
<app-preloaderlayoutportrait
  *ngIf="utilService.showAppPreloader && isPortrait"
  style="
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.99);
    z-index: 50;
  "
  [currentLanguage]="appLanguage"
  [layoutdata]="{
    stageWidth: 720,
    stageHeight: 1280,
    stageLeft: 0,
    stageTop: 0,
    stageZ: 1000,
    position: 'absolute',
    overflow: 'hidden',
    name: 'updatescale'
  }"
  [manifest]="[]"
></app-preloaderlayoutportrait>
<div
  id="main"
  [class]="translateCurrentLang"
  class="main_class"
  style="touch-action: manipulation !important"
>
  <router-outlet></router-outlet>
</div>

<!-- <textarea id="loggerr" style="position: fixed; left: 0px; top: 0px; width: 200px; height: 150px; z-index: 1111111; background-color: white; opacity: 1;" class="translate-top-most"></textarea> -->
