import { FC21 } from './fc21';
import { FCB } from './fcb';

interface GameSettings {
  game: string;
  gameType: string;
  gameName: string;
  tableType: string;
  featureName: string;
  isComingSoon: boolean;
  isSecret: boolean;
}

/**
 * Created by karthikeyan.k on 09-09-2019.
 */
interface Settings {
  language: string;
  fc21: FC21;
  fcb: FCB;
  appSettings: Partial<AppSettings>;
}

const fc21 = new FC21();
const fcb = new FCB();

export const Settings: Settings = {
  language: 'en',
  fc21,
  fcb,
  appSettings: {},
};

interface AppSettings {
  shufflevideolimitinmins: number;
  dealerresultinterval: number;
  liveCasinoIdleDurationSeconds: number;
  historyURI: string;
  lobby: {
    fc21: GameSettings[];
    fc21mobile: GameSettings[];
    fcb: GameSettings[];
  };
  menu: {
    fc21: {
      settings: {
        isComingSoon: boolean;
      };
      tutorials: {
        isComingSoon: boolean;
        Generalgameinfo: boolean;
      };
      quickkeys: {
        isComingSoon: boolean;
      };
      videotutorials: {
        isComingSoon: boolean;
      };
      premiumpay: {
        isComingSoon: boolean;
      };
      history: {
        isComingSoon: boolean;
      };
    };
    fcb: {
      settings: {
        isComingSoon: boolean;
      };
      skipbanner: {
        isSkipSliderArray: number[];
        previousbanner: number;
        nextbaner: number;
        isSliderbanner: boolean;
      };
      tutorials: {
        isComingSoon: boolean;
      };
      quickkeys: {
        isComingSoon: boolean;
      };
      videotutorials: {
        isComingSoon: boolean;
      };
      premiumpay: {
        isComingSoon: boolean;
      };
      history: {
        isComingSoon: boolean;
      };
    };
  };
  checkViasinoCasinoidArray: string[];
  checkRelaxCasinoidArray: (string | number)[];
  iscardShufflingEnable: boolean;
}
