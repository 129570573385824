/**
 * Created by karthikeyan.k on 09-09-2019.
 */
export class FCB {
  commission = 5;
  dealSpeed = 0;
  gameName = 'fcb';
  gameSound = 'on';
  voiceSound = 'on';
  volume = 1;
}
