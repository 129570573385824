import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppErrorComponent } from './apperror.component';
import { AppErrorLayoutComponent } from './core/apperror.layout.component';
import { AppErrorLayoutDesktopComponent } from './desktop/apperror.layout.desktop.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DirectiveModule } from '../../directives/directive.module';

@NgModule({
  declarations: [
    AppErrorComponent,
    AppErrorLayoutComponent,
    AppErrorLayoutDesktopComponent,
  ],
  imports: [CommonModule, TranslateModule, DirectiveModule, FormsModule],
  exports: [
    AppErrorComponent,
    AppErrorLayoutComponent,
    AppErrorLayoutDesktopComponent,
  ],
})
export class AppErrorModule {}
