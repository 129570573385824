import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableInfopopupComponent } from './tableinfopopup.component';
import { TableInfopopupLayoutComponent } from './core/tableinfopopup.layout.component';
import { TableInfopopupLayoutDesktopComponent } from './desktop/tableinfopopup.layout.desktop.component';
import { FormsModule } from '@angular/forms';
import { DirectiveModule } from '../../directives/directive.module';
import { TranslateModule } from '@ngx-translate/core';
import { PipeModule } from '../../pipe/pipe.module';
import { TableinfopopupLayoutMobileComponent } from './mobile/tableinfopopup.layout.mobile.component';

@NgModule({
  declarations: [
    TableInfopopupComponent,
    TableInfopopupLayoutComponent,
    TableInfopopupLayoutDesktopComponent,
    TableinfopopupLayoutMobileComponent,
  ],
  imports: [
    CommonModule,
    DirectiveModule,
    PipeModule,
    TranslateModule,
    FormsModule,
  ],
  exports: [
    TableInfopopupComponent,
    TableInfopopupLayoutComponent,
    TableInfopopupLayoutDesktopComponent,
    TableinfopopupLayoutMobileComponent,
  ],
})
export class TableInfopopupModule {}
