import { LoaderService } from './../../../services/loader.service';
import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { SpriteCacheService } from '../../../services/spritecache.service';
import { UtilService } from '../../../util/UtilService';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: '../../../template/noop.template.html',
})
export class PreloaderLayoutComponent implements OnInit, OnChanges, OnDestroy {
  @Input() manifest: any;
  @Input() moduleName = 'fcg';
  @Input() error = false;
  @Input() currentLanguage;
  _subscriptionScale: Subject<any>;
  queue;
  progressTime = 0;
  @Output() progressData: EventEmitter<number> = new EventEmitter();
  @Output() downloadComplete: EventEmitter<any> = new EventEmitter();
  @Input() scaleR: any = {
    scale: 1,
    scaleX: 1,
    scaleY: 1,
    offsetLeft: 0,
    offsetTop: 0,
  };
  isGameIconLoaded = false;
  isPortrait = false;
  isLoadCompleted = false;

  constructor(
    public zone: NgZone,
    public spriteCacheService: SpriteCacheService,
    public _utilService: UtilService,
    public loaderService: LoaderService,
    public translate: TranslateService
  ) {
    this.loaderService.loadCompleteChange.subscribe((value) => {
      if (value == 'preloader') {
        this.loadComplete();
      }
    });
    this.loaderService.progress.subscribe((value) => {
      this.handleProgress(value);
    });
    this._subscriptionScale = _utilService.scaleChange;
    this._subscriptionScale.subscribe((value) => {
      // console.log('_subscriptionScale');
      // console.log(value);
      let resizeTimeOut = setTimeout(() => {
        this.isPortrait = window.innerWidth > window.innerHeight ? false : true;
        window.clearTimeout(resizeTimeOut);
      }, 300);

      this.scaleR = value;
    });
  }

  init() {
    this.isGameIconLoaded = false;
    this.isLoadCompleted = false;
    this.loadManifest();
  }

  ngOnInit() {
    // this.init();
    //console.log("hfhjdhgcgjcghvhgvjhjhbjb");
  }

  ngOnChanges(data) {
    window.parent.postMessage(['setHeight', 0], '*');
    //console.log("hfhjdhgcgjcghvhgvjhjhbjb");
    if (data.manifest) {
      this.manifest = data.manifest.currentValue;
      if (this.manifest != undefined && this.manifest.length > 0) {
        // console.log('Preloader NG ON CHANGES');
        document.body.style.overflow = 'hidden';
        this.init();
      }
    }
  }

  onGameImageload = () => {
    this.isGameIconLoaded = true;
  };

  count = 0;

  loadManifest() {
    this.spriteCacheService.rootContainer = undefined;
    this.spriteCacheService.rootContainer = {};
    this.loaderService.loadImages(this.manifest, 'preloader');
  }

  loadComplete = () => {
    if (!this.isLoadCompleted) {
      this.isLoadCompleted = true;
      document.body.style.overflow = 'auto';
      this.downloadComplete.emit({});
    }
  };
  handleProgress = (e) => {
    if (!this.isLoadCompleted) {
      this.progressTime = e;
      // console.log('progress---------------preloader-------------'+this.progressTime);
      if (this.progressTime > 100) {
        this.progressTime = 100;
      }
      this.progressData.emit(this.progressTime);
    }
  };

  ngOnDestroy() {
    // console.log('Preloader NG ON DESTROY');
    document.body.style.overflow = 'auto';
  }

  get isLangFromRightToLeft() {
    return this.currentLanguage === 'he' || this.currentLanguage === 'ar';
  }

  get loadingText() {
    if (this.error) {
      return this.translate.instant('LANG00138');
    }

    return `${this.translate.instant('LANG00002')} ${this.progressTime}${
      this.isLangFromRightToLeft ? ' ' : ''
    }%`;
  }
}
