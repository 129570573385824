import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectHistoryComponent } from './selecthistory.component';
import { SelectHistoryLayoutComponent } from './core/selecthistory.layout.component';
import { SelectHistoryLayoutDesktopComponent } from './desktop/selecthistory.layout.desktop.component';
import { FormsModule } from '@angular/forms';
import { DirectiveModule } from '../../directives/directive.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    SelectHistoryComponent,
    SelectHistoryLayoutComponent,
    SelectHistoryLayoutDesktopComponent,
  ],
  imports: [CommonModule, DirectiveModule, TranslateModule, FormsModule],
  exports: [
    SelectHistoryComponent,
    SelectHistoryLayoutComponent,
    SelectHistoryLayoutDesktopComponent,
  ],
})
export class SelectHistoryModule {}
