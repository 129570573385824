/**
 * Created by karthikeyan on 22/11/2018.
 */
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable()
export class $http {
  constructor(@Inject(HttpClient) private http: HttpClient) {}

  post(
    apiURL: string,
    data: any,
    contentRaw?: Boolean,
    responseType?: string,
    isTimeStamp?: boolean
  ): Observable<any> {
    let headers: HttpHeaders;
    const headerOptions =
      contentRaw === true
        ? { 'Content-Type': 'application/json' }
        : { 'Content-Type': 'application/x-www-form-urlencoded' };
    headers = new HttpHeaders(headerOptions);
    const options = {
      headers: headers,
      responseType: responseType
        ? (responseType as 'json')
        : ('json' as 'json'),
    };
    if (isTimeStamp === undefined || isTimeStamp === true) {
      data.gdt = Date.now();
    }
    const postData =
      contentRaw === true ? data : this.convertToJsonToParam(data);
    // return this.http.post(apiURL, postData, options);
    return this.http.post(apiURL, postData, options);
  }

  get(apiURL: string, data: any, contentRaw?: boolean): Observable<any> {
    data.gdt = Date.now();
    const queryString = this.convertToJsonToParam(data);
    if (contentRaw === true) {
      return this.http.get(apiURL + '?' + queryString, {
        responseType: 'text',
      });
    }
    return this.http.get(apiURL + '?' + queryString);
  }

  convertToJsonToParam(data: Object) {
    const recursiveDecoded = decodeURIComponent($.param(data));
    return recursiveDecoded;
  }
}
