import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DirectiveModule } from '../../directives/directive.module';
import { PipeModule } from '../../pipe/pipe.module';
import { alertLiveDealerNoSeatAvailableComponent } from './mobile/alertlivedealernoseatavailable.layout.mobile.component';

@NgModule({
  declarations: [alertLiveDealerNoSeatAvailableComponent],
  imports: [CommonModule, TranslateModule, DirectiveModule, PipeModule],
  exports: [alertLiveDealerNoSeatAvailableComponent],
})
export class AlertLiveDealerNoSeatAvailableTrigger {}
