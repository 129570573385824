import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencyFormatPipe } from './currency.format.pipe';
import { DateTimeFormatPipe } from './datetime.format.pipe';
import { UserCurrencyPipe } from './user.currency.pipe';
import { CurrencySymbolPipe } from './currency.symbol.pipe';
import { ReplaceYearPipe } from './replace.year.pipe';

@NgModule({
  declarations: [
    CurrencyFormatPipe,
    DateTimeFormatPipe,
    UserCurrencyPipe,
    CurrencySymbolPipe,
    ReplaceYearPipe,
  ],
  imports: [CommonModule],
  exports: [
    CurrencyFormatPipe,
    DateTimeFormatPipe,
    UserCurrencyPipe,
    CurrencySymbolPipe,
    ReplaceYearPipe,
  ],
})
export class PipeModule {}
