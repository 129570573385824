import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlayForFunAlertComponent } from './playforfunalert.component';
import { PlayForFunAlertLayoutComponent } from './core/playforfunalert.layout.component';
import { PlayForFunAlertLayoutDesktopComponent } from './desktop/playforfunalert.layout.desktop.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DirectiveModule } from '../../directives/directive.module';
import { PipeModule } from '../../pipe/pipe.module';
import { PlayForFunAlertLayoutMobileComponent } from './mobile/playforfunalert.layout.mobile.component';
import { MobileHeaderModule } from '../mobileheader/mobile.header.module';

@NgModule({
  declarations: [
    PlayForFunAlertComponent,
    PlayForFunAlertLayoutComponent,
    PlayForFunAlertLayoutDesktopComponent,
    PlayForFunAlertLayoutMobileComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    DirectiveModule,
    MobileHeaderModule,
    PipeModule,
    FormsModule,
  ],
  exports: [
    PlayForFunAlertComponent,
    PlayForFunAlertLayoutComponent,
    PlayForFunAlertLayoutDesktopComponent,
    PlayForFunAlertLayoutMobileComponent,
  ],
})
export class PlayForFunAlertModule {}
