import { Component, Input, NgZone, OnDestroy } from '@angular/core';
import { PreloaderLayoutComponent } from '../core/preloader.layout.component';
import { Istage } from '../../../../framework/Istage';
import { UtilService } from '../../../util/UtilService';
import { LoaderService } from 'src/app/services/loader.service';
import { SpriteCacheService } from 'src/app/services/spritecache.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-preloaderlayoutportrait',
  templateUrl: '../desktop/preloaderresizeable.component.html',
  styleUrls: ['../css/preloader.portrait.component.css'],
})
export class PreloaderLayoutMobilePortrait
  extends PreloaderLayoutComponent
  implements OnDestroy
{
  @Input() layoutdata: Istage;
  @Input() fontSizeVal: number;
  loadingNameFontSize = 23;
  loadingTextLayout: Istage = {
    stageWidth: 449,
    stageHeight: 42,
    stageLeft: 150,
    stageTop: 913,
    stageZ: 1005,
    position: 'absolute',
  };

  constructor(
    public zone: NgZone,
    public spriteCacheService: SpriteCacheService,
    public _utilService: UtilService,
    public loaderService: LoaderService,
    public translate: TranslateService
  ) {
    super(zone, spriteCacheService, _utilService, loaderService, translate);
  }

  ngOnDestroy() {
    /*  console.log('Preloader NG ON DESTROY');
    document.body.style.overflowY = 'auto';
    document.body.style.overflowX = 'hidden';*/
  }
}
