import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageSpriteDirective } from './imagesprite/imagesprite.directive';
import { BodybgDirective } from './bodybg/bodybg.directive';
import { DatePickerDirective } from './datepicker/datepicker.directive';
import { WindowHeightDirective } from './windowheight/windowheight.directive';
import { ClickOutsideDirective } from './clickoutside/clickoutside.directive';
import { NumberDirective } from './validation/number.directive';
import { ToolTipDirective } from './tooltip/tooltip.directive';

@NgModule({
  declarations: [
    ImageSpriteDirective,
    BodybgDirective,
    DatePickerDirective,
    WindowHeightDirective,
    ClickOutsideDirective,
    NumberDirective,
    ToolTipDirective,
  ],
  imports: [CommonModule],
  exports: [
    ImageSpriteDirective,
    BodybgDirective,
    DatePickerDirective,
    WindowHeightDirective,
    ClickOutsideDirective,
    NumberDirective,
    ToolTipDirective,
  ],
})
export class DirectiveModule {}
