/**
 * Created by karthikeyan.k on 29-11-2018.
 */

export class PageConstant {
  public static get INDEX(): string {
    return 'index';
  }

  public static get INDEXMOBILE(): string {
    return 'indexmobile';
  }
  public static get ERROR(): string {
    return 'error';
  }
  public static get ERRORMOBILE(): string {
    return 'errormobile';
  }
  public static get RFAMALTA(): string {
    return 'rfamalta';
  }
  public static get FCG(): string {
    return 'fcg';
  }

  public static get FCGMOBILE(): string {
    return 'fcgmobile';
  }

  public static get FC21(): string {
    return 'fc21';
  }

  public static get FC21MOBILE(): string {
    return 'fc21mobile';
  }

  public static get FCB(): string {
    return 'fcb';
  }

  public static get FCBMOBILE(): string {
    return 'fcbmobile';
  }

  public static get FC21TABLES(): string {
    return 'fc21tables';
  }
  public static get FCBTABLES(): string {
    return 'fcbtables';
  }

  public static get FC21TABLESMOBILE(): string {
    return 'fc21tablesmobile';
  }

  public static get FCBTABLESMOBILE(): string {
    return 'fcbtablesmobile';
  }

  public static get HISTORYDETAIL(): string {
    return 'historydetail';
  }
  public static get HISTORY(): string {
    return 'history';
  }

  public static get GAME(): string {
    return 'game';
  }

  public static get REPORTS(): string {
    return 'reports';
  }

  public static get HISTORYSUMMARY(): string {
    return 'historysummary';
  }

  public static get HISTORYSUMMARYCONDENSED(): string {
    return 'historysummarycondensed';
  }

  public static get DESKTOP(): string {
    return 'desktop';
  }

  public static get MOBILE(): string {
    return 'mobile';
  }

  private static version: string = '1_0_0';

  public static get VERSION(): string {
    return this.version;
  }

  public static set VERSION(versionId: string) {
    this.version = versionId;
  }
}
